
























import { Component, Prop, Vue } from 'vue-property-decorator'
import { getGroupedPosts } from '~plugins/utils'
import SidebarWidget from '~components/sidebar/SidebarWidget.vue'
import Layout1 from '~components/content/layouts/Layout-1.vue'
import Layout2 from '~components/content/layouts/Layout-2.vue'
import Layout3 from '~components/content/layouts/Layout-3.vue'
import Layout4 from '~components/content/layouts/Layout-4.vue'

import { PostsInLayout, EnhancedPost, Layout } from '~base/common/types'

const defaultLayouts: Layout[] = [
  { component: Layout1, items: 1 },
  { component: Layout2, items: 2 },
  { component: Layout4, items: 2 },
  { component: Layout1, items: 1 },
  { component: Layout4, items: 2 },
  { component: Layout2, items: 2 },
]

@Component({
  components: {
    Layout1,
    Layout2,
    Layout3,
    Layout4,
    SidebarWidget,
  },
})
export default class Posts extends Vue {
  @Prop({ required: true, type: Array }) readonly posts!: EnhancedPost[]
  @Prop({ type: String, default: 'frontpage' }) readonly sidebar!: string
  @Prop({ type: Array, default: () => defaultLayouts })
  readonly layouts!: Layout[]

  get groupedPosts(): PostsInLayout[] {
    return getGroupedPosts(this.posts, this.layouts)
  }

  hasWidgetAtIndex(index: number): boolean {
    return this.$store.getters['frontpage/hasWidgetAtIndex'](
      this.sidebar,
      index
    )
  }

  getWidgetsAtIndex(index: number): SidebarWidget[] {
    return this.$store.getters['frontpage/getWidgetsAtIndex'](
      this.sidebar,
      index
    )
  }
}
