<template>
  <div>
    <SeoPagination
      v-if="currentPage !== 1"
      :hits="hits"
      :current-page="currentPage"
      :link-prefix="linkPrefix"
    />
    <div v-else-if="hasNextPage" ref="loadMore" />
    <noscript inline-template>
      <SeoPagination
        v-if="currentPage === 1"
        :hits="hits"
        :current-page="currentPage"
        :link-prefix="linkPrefix"
      />
    </noscript>
  </div>
</template>

<script>
import SeoPagination from './SeoPagination.vue'
import { addLoadMore, stopLoadMore } from '~plugins/load-more.js'

export default {
  components: {
    SeoPagination,
  },
  props: {
    hasNextPage: {
      type: Boolean,
      default: false,
    },
    hits: {
      type: Number,
      default: 0,
    },
    linkPrefix: {
      type: String,
      required: true,
    },
    endlessScrollerCallback: {
      type: Function,
      default: undefined,
    },
  },
  computed: {
    currentPage() {
      return this.$route.params.page ? +this.$route.params.page : 1
    },
  },
  mounted() {
    if (!this.endlessScrollerCallback) return
    setTimeout(() => {
      const reference = this.$refs.loadMore
      addLoadMore(reference, () => {
        if (!this.hasNextPage) {
          stopLoadMore()
          return
        }

        this.endlessScrollerCallback()
      })
    }, 2000)
  },
}
</script>
