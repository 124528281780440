<template>
  <div class="mb-8 flex justify-center">
    <div class="flex items-center">
      <a v-if="pages.prev" :href="pages.prev.link">
        <svg
          class="h-8 w-8"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.818 9.646L8.465 10l.353.354 3.889 3.889-.707.707L7.05 10l.354-.353L12 5.05l.707.707-3.889 3.89z"
            fill="#CCC"
            stroke="#000"
          />
        </svg>
      </a>

      <div class="flex items-center" data-testid="seo-pagination">
        <a
          v-for="(item, index) in pages.items"
          :key="index"
          :class="item.stepClass"
          :href="item.link"
          class="pagination-page"
        >
          {{ item.page }}
        </a>
      </div>

      <a v-if="pages.next" :href="pages.next.link">
        <svg
          class="h-8 w-8"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.293 5.757L8 5.05 12.95 10l-.354.353L8 14.95l-.707-.707 3.889-3.89.353-.353-.353-.354-3.889-3.889z"
            fill="#CCC"
            stroke="#000"
          />
        </svg>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hits: {
      type: Number,
      required: true,
    },
    linkPrefix: {
      type: String,
      required: true,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      pages: this.calculatePages(),
    }
  },
  methods: {
    calculatePages() {
      const pageNumbers = []
      const maxPages = Math.ceil(this.hits / this.$config.perPage)
      const currentPage = isNaN(this.currentPage) ? 1 : this.currentPage
      const stepper = []

      stepper[1] = maxPages < 10 ? [1, 2, 3, 4, 5] : [1, 2]
      if (maxPages >= 10) {
        stepper[10] = [0, 1, 2]
      }
      if (maxPages > 100) {
        stepper[100] = [0, 1, 2]
      }

      pageNumbers.push(this.currentPage)
      stepper.forEach((steps, factor) => {
        steps.forEach((step) => {
          step *= factor
          let pageNumber = Math.ceil((currentPage + step) / factor) * factor
          if (pageNumbers.includes(pageNumber)) {
            pageNumber = pageNumber + factor
          }
          if (pageNumber <= maxPages && pageNumber > 0) {
            pageNumbers.push(pageNumber)
          }

          pageNumber = Math.floor((currentPage - step) / factor) * factor
          if (pageNumbers.includes(pageNumber)) {
            pageNumber = pageNumber - factor
          }
          if (currentPage > factor && pageNumber > 0) {
            pageNumbers.push(pageNumber)
          }
        })
      })

      if (!pageNumbers.includes(1)) pageNumbers.push(1)
      if (!pageNumbers.includes(maxPages)) pageNumbers.push(maxPages)

      pageNumbers.sort((a, b) => (a < b ? -1 : 1))

      const paginationSteps = []
      pageNumbers.forEach((page) => {
        if (isNaN(page)) return
        let stepClass
        switch (page) {
          case currentPage:
            stepClass = 'current'
            break
          case 1:
            stepClass = 'first'
            break
          case maxPages:
            stepClass = 'last'
            break
        }
        paginationSteps.push({
          page,
          link:
            page === 1
              ? `${this.linkPrefix === '' ? '/' : this.linkPrefix}`
              : `${this.linkPrefix}/page/${page}`,
          stepClass,
        })
      })

      const pagination = {
        items: paginationSteps,
      }

      if (currentPage > 1) {
        pagination.prev = {
          page: currentPage - 1,
          link:
            currentPage === 2
              ? `${this.linkPrefix}`
              : `${this.linkPrefix}/page/${currentPage - 1}`,
        }
      }
      if (maxPages > currentPage) {
        pagination.next = {
          page: currentPage + 1,
          link: `${this.linkPrefix}/page/${currentPage + 1}`,
        }
      }

      return pagination
    },
  },
}
</script>

<style>
.pagination-page {
  @apply mr-2 flex items-center justify-center bg-black-800 p-2 text-black-000;
  min-width: 2rem;

  &.current {
    @apply bg-red text-white;
  }

  &:hover {
    @apply font-bold;
  }

  &:last-child {
    @apply mr-0;
  }
}
</style>
