import _debounce from 'lodash.debounce'
const isModernBrowser = process.client && 'IntersectionObserver' in window

let observer
let debouncedObserver
let observedTarget

export function addLoadMore(target, cb) {
  if (!target) {
    return
  }
  observedTarget = target
  if (isModernBrowser) {
    observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => entry.isIntersecting && cb())
      },
      {
        rootMargin: '500px 0px',
        threshold: 0.1,
      }
    )
    observer.observe(observedTarget)
  } else {
    debouncedObserver = _debounce(
      () => {
        if (observedTarget) {
          if (
            observedTarget.offsetTop - 500 <
            (window.scrollY || window.pageYOffset) + screen.height
          ) {
            cb()
          }
        }
      },
      250,
      { maxWait: 500 }
    )

    document.addEventListener('scroll', debouncedObserver)
    window.addEventListener('resize', debouncedObserver)
    window.addEventListener('orientationChange', debouncedObserver)
  }
}

export function stopLoadMore() {
  if (isModernBrowser) {
    observer.unobserve(observedTarget)
  } else {
    document.removeEventListener('scroll', debouncedObserver)
    window.removeEventListener('resize', debouncedObserver)
    window.removeEventListener('orientationChange', debouncedObserver)
  }
}
