










import { Component, Prop, Vue } from 'vue-property-decorator'
import Post from '~components/content/Post.vue'

@Component({
  components: {
    Post,
  },
})
export default class Layout3 extends Vue {
  @Prop({ type: Array }) readonly posts!: Post[]
  @Prop({ type: Number }) readonly layoutIndex!: number
}
