import { Vue } from 'vue-property-decorator'
import { enhance } from '~plugins/utils'

import {
  EnhancedPost,
  SearchResult,
  SearchResultEntry,
  VueLinkProperty,
  ApolloOperationVariables,
  VueMetaProperty,
  ListingPageType,
} from '~base/common/types'

export interface ListingPageVariables extends ApolloOperationVariables {
  first: number
  after?: string
  categories?: string[]
  tags?: string[]
  query?: string | (string | null)[]
  page?: number
  order?: string
}

export abstract class ListingPage extends Vue {
  public search: SearchResult = {
    pageInfo: { hasPreviousPage: false, hasNextPage: false },
    hits: 0,
    nodes: [],
  }

  protected type: ListingPageType = 'category'
  protected settingsKey: string | undefined = undefined
  protected after: string = ''
  private fetched: string[] = []
  protected posts: (EnhancedPost | undefined)[] = []
  protected pageSettings: any = {}

  abstract get linkPrefix(): string

  created() {
    if (this.settingsKey) {
      const settings = this.$store.getters[`news/${this.type}Settings`]
      if (Object.prototype.hasOwnProperty.call(settings, this.settingsKey)) {
        this.pageSettings = settings[this.settingsKey]
      }
    }
  }

  get variables(): ListingPageVariables {
    const variables: ListingPageVariables = {
      first: this.perPage,
    }
    if (this.$route.params.page) {
      variables.page = parseInt(this.$route.params.page)
    }
    return variables
  }

  get perPage(): number {
    return parseInt(this.$config.perPage || '20')
  }

  get link() {
    const currentPage = this.$route.params.page ? +this.$route.params.page : 1
    const canonicalLink =
      (this.$config.publicUrl || '') +
      (currentPage === 1
        ? this.linkPrefix
        : `${this.linkPrefix}/page/${currentPage}`)

    const links: VueLinkProperty[] = [
      { hid: 'canonical', rel: 'canonical', href: canonicalLink },
    ]
    if (this.search) {
      const { hasNextPage, hasPreviousPage } = this.search.pageInfo

      if (hasPreviousPage) {
        const prevLink =
          currentPage === 2
            ? this.linkPrefix
            : `${this.linkPrefix}/page/${currentPage - 1}`
        links.push({ rel: 'prev', href: prevLink })
      }
      if (hasNextPage) {
        const nextLink = `${this.linkPrefix}/page/${currentPage + 1}`
        links.push({ rel: 'next', href: nextLink })
      }
    }

    if (this.pageSettings.head?.link) {
      links.push(...this.pageSettings.head.link)
    }

    return links
  }

  get titleComponent(): object | undefined {
    if (this.pageSettings.headerComponent) {
      if (typeof this.pageSettings.headerComponent === 'function') {
        return this.pageSettings.headerComponent
      }
      return () =>
        import(
          /* webpackChunkName: "tag-[request]" */ `~components/content/widgets/listings/${this.pageSettings.headerComponent}.vue`
        )
    }
    return undefined
  }

  get meta(): VueMetaProperty[] {
    const currentPage = this.$route.params.page ? +this.$route.params.page : 1
    const meta: VueMetaProperty[] = []

    if (this.search && currentPage > 1) {
      meta.push({
        hid: 'robots',
        name: 'robots',
        content: 'noindex,follow',
      })
    }

    return meta
  }

  gotResult(data: { search: SearchResult }) {
    if (data && data.search) {
      this.posts = data.search.nodes.map((p: SearchResultEntry) =>
        enhance(p, this.$config)
      )
    }
  }

  beforeMount() {
    this.$store.dispatch('news/pageChanged')
  }

  nextPage() {
    if (
      this.$apollo.queries.search &&
      this.$apollo.queries.search.fetchMore &&
      !this.fetched.includes(this.after) // avoid calling fetchmore twice for the same hash...
    ) {
      this.fetched.push(this.after)
      this.$apollo.queries.search.fetchMore({
        variables: {
          ...this.variables,
          after: this.after,
        } as ListingPageVariables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          // debugger
          return {
            search: {
              __typename: previousResult.search.__typename,
              nodes: [
                ...previousResult.search.nodes,
                ...fetchMoreResult.search.nodes,
              ],
              pageInfo: fetchMoreResult.search.pageInfo,
              hits: fetchMoreResult.search.hits,
            },
          }
        },
      })
    }
  }
}
